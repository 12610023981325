@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0 auto;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

address {
  text-decoration: none;
  font-style: normal;
}

.main-container {
  height: 100%;
  width: 100%;
  display: grid;
  background-image: linear-gradient(45deg, #274873, #203140);
  min-height: 100vh;
  /* min-width: 28rem; */
}

/* .navparent {
  min-width: 28rem;
  display: flex;
  font-family: Roboto Condensed;
  font-size: 20px;
  margin-top: -4rem;
  justify-content: center;
  color: #f2d57e;
} */

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.desktop-only {
  width: 100%;
}

.mobile-only {
  display: none;
  z-index: 2;
}

.nav-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 1.6em;
  margin: 3.2em auto 0 auto;
  justify-content: space-between;
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 1.3rem;
  color: #f2d57e;
}

.nav-mobile-container {
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 1.3rem;
  color: #f2d57e;
}

.center-nav {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 2rem;
  justify-content: space-around;
  align-self: center;
  margin-top: 2.5rem;
  z-index: 1;
  width: 91%;
}

.mobile-nav {
  position: absolute;
  top: 2.4rem;
  right: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  text-align: right;
  z-index: 1;
}

.mobile-nav-li {
  width: 12.5rem;
  font-size: 2.2rem;
}

.nav-item {
  padding: 2px 4px;
  /* margin: 0 2.5rem; */
  cursor: pointer;
  justify-self: center;
  align-self: center;
}

.nav-item:hover {
  color: #f9f9f9;
  cursor: pointer;
}

.mobile-nav-item {
  padding: 2px 4px;
  /* margin: 0 2.5rem; */
  cursor: pointer;
  justify-self: left;
  align-self: center;
  background-color: #f2d57e;
  color: #132a42;
  padding-top: 0.4rem;
  padding-right: 0.7rem;
}

.mobile-nav-item:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.menu-open {
  height: auto;
  width: 3rem;
  padding: 0.2rem;
  color: #f2d57e;
  margin-left: 9.7rem;
  cursor: pointer;
}

.menu-open:hover {
  color: #f9f9f9;
}

.nav-cursor {
  padding: 9%;
  text-align: center;
  font-size: 25px;
}

.intro-page-content {
  display: grid;
  grid-template-columns: auto;
}

.intro-active {
  color: #f9f9f9;
  padding: 4px 6px;
  background-color: #5f5aff;
  cursor: pointer;
  font-weight: 800;
  font-family: Roboto;
}

.work-active {
  color: #f9f9f9;
  padding: 4px 6px;
  background-color: #ff5a5a;
  cursor: pointer;
  font-weight: 800;
  font-family: Roboto;
}

.services-active {
  color: #f9f9f9;
  padding: 4px 6px;
  background-color: #23c273;
  cursor: pointer;
  font-weight: 800;
  font-family: Roboto;
}

.about-active {
  color: #f9f9f9;
  padding: 4px 6px;
  background-color: #bd4aff;
  cursor: pointer;
  font-weight: 800;
  font-family: Roboto;
}

.contact-active {
  color: #3b4756;
  padding: 0.3em;
  background-color: #f2d57e;
  font-weight: 900;
}

.contact {
  font-size: 1.7rem;
  font-family: Roboto Condensed, Sans-Serif;
  justify-self: center;
  align-self: center;
  z-index: 1;
}

.logo-small-container {
  font-size: 24px;
  text-align: center;
  width: 100%;
  display: grid;
  justify-content: center;
}

.logo-small {
  width: 13rem;
  margin-top: 2.4rem;
  margin-right: 3rem;
}

.logo-small-mobile {
  width: 13rem;
  margin-top: 3rem;
}

.contact:hover {
  color: #f9f9f9;
  cursor: pointer;
}

.h1 {
  color: #f2d57e;
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.h2 {
  color: #f9f9f9;
  font-family: Roboto, Sans-Serif;
  font-size: 24px;
  font-weight: bold;
}

.p1 {
  color: #f9f9f9;
  font-family: Roboto Condensed, Sans-Serif, Sans-Serif;
  font-size: 2rem;
  font-weight: 300;
}

.p2 {
  color: #f2d57e;
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 24px;
}

.p2 {
  color: #f2d57e;
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 24px;
}

.h1.about {
  margin-bottom: 0;
}

.intro-headings {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 1rem;
  justify-content: center;
  justify-self: center;
  margin: 1rem auto;
  font-family: Roboto Condensed, Sans-Serif;
  font-size: 2.5rem;
  color: #f2d57e;
  width: auto;
  font-weight: bold;
  /* min-width: 28rem; */
}

.text-diamond {
  font-size: 1.5rem;
  align-self: center;
  justify-self: center;
}

.intro-heading-item {
  justify-self: center;
}

.intro-p {
  text-align: center;
  justify-content: center;
  width: auto;
  padding: 1%;
  margin: 2rem 43rem;
}

.page-button {
  width: auto;
}

.page-button.bottom {
  margin-top: 2rem;
  margin-bottom: 10rem;
}

.button-container {
  display: grid;
  grid-template-columns: auto auto;
  border: 2px solid #f2d57e;
  color: #f9f9f9;
  font-family: Roboto Condensed, sans-serif;
  font-weight: bold;
  transition: 300ms;
  background-color: #254061;
}

.button-container:hover {
  border-color: #f9f9f9;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #132a42;
  -webkit-filter: drop-shadow(0px 0px 43px rgba(249, 249, 249, 0.2));
          filter: drop-shadow(0px 0px 43px rgba(249, 249, 249, 0.2));
}

.button-text {
  font-size: 3.5rem;
  padding: 0 0.7rem;
  white-space: nowrap;
}

.button-direction {
  font-size: 3.5rem;
  align-self: center;
  width: 3rem;
  height: auto;
  background-color: #f2d57e;
  color: #254061;
  padding: 0.6rem 0;
  padding-left: 0.2rem;
}

.button-direction.L {
  padding-left: 0;
  padding-right: 0.2rem;
}

.footer {
  width: 100%;
  /* min-width: 28rem; */
  height: 101px;
  background-color: #f9f9f9;
  border-style: solid;
  border-width: 2px 0px 0px 0px;
  border-color: #3b4756;
  font-family: Roboto, Sans-Serif;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
}

.social-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.copyright {
  justify-self: center;
  text-align: center;
  align-self: center;
}

.back-to-top {
  align-self: center;
}

.socialIcon {
  padding: 0px 15px;
  cursor: pointer;
}

.mailicon {
  margin-top: 3px;
}

.back-to-top {
  cursor: pointer;
}

.heading {
  font-family: Roboto, Sans-Serif;
  font-weight: bold;
  font-size: 4.3rem;
  color: #f9f9f9;
  border-style: solid;
  border-width: 3px;
  padding-left: 0.9rem;
  padding-right: 0.7rem;
  white-space: nowrap;
  align-self: center;
  grid-area: 1 / 1;
}

.heading2 {
  font-family: Roboto, Sans-Serif;
  font-weight: bold;
  font-size: 4.4vw;
  color: #f9f9f9;
  display: inline-block;
  margin: 0.6vh 8vh 8vh 8vh;
  border-style: solid;
  border-width: 3px;
  padding: 0px 10px;
}

.button {
  color: #3b4756;
  font-family: Roboto, Sans-Serif;
  font-weight: bold;
  font-size: 30px;
  background-color: #f2d57e;
  padding: 6px;
  -webkit-filter: drop-shadow(12px 7px 15px #132a42);
          filter: drop-shadow(12px 7px 15px #132a42);
  margin: 0 45.3rem;
}

.button:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.main-logo-container {
  text-align: center;
  margin: 3rem auto 1rem auto;
  padding: 1.3% 1.4%;
  width: 42rem;
}

.page-title-container {
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: 4.2rem;
  margin-bottom: 1.2rem;
  display: grid;
  width: 20vw;
}

.page-title {
  align-self: center;
  display: grid;
}

.text-bg-container {
  width: 23rem;
  align-self: center;
  grid-area: 1 / 1;
}

.text-bg-diamond {
  width: 100%;
}

.page-item {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 3rem;
  margin-left: 12rem;
  margin-right: 15rem;
}

.page-item-text {
  align-self: center;
}

.page-item-img {
  width: 22rem;
  height: auto;
  -webkit-filter: drop-shadow(12px 7px 15px #132a42);
          filter: drop-shadow(12px 7px 15px #132a42);
  align-self: center;
}

.diamonds-container {
  justify-self: center;
  width: 6rem;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.diamonds {
  width: 100%;
  margin-top: 1rem;
}

.diamond-period {
  font-size: 1.5rem;
}

.work-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
  margin-bottom: 4rem;
}

.videosmall {
  width: 29rem;
  height: 16rem;
  border-width: 0;
}

.work-hr {
  border-color: #f9f9f9;
  margin: 0.8rem 0 0.5rem 0;
}

.video-container {
  font-family: Roboto, sans-serif;
  font-weight: 900;
  color: #f9f9f9;
  font-size: 2rem;
}

.text-bg-container.contact {
  width: 37rem;
}

.heading.contact {
  font-size: 6.3rem;
}

.contact-details {
  text-align: center;
  margin-top: 7rem;
}

.contact-number {
  margin-bottom: 2rem;
  text-decoration: none;
}
.email-add {
  text-decoration: none;
  color: #f2d57e;
  font-family: Roboto Condensed;
  font-size: 1.5rem;
}
.description {
  color: #f2d57e;
  font-family: Roboto;
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.work-title {
  background-color: #ff5a5a;
  display: inline;
  padding: 0.2rem 0.7rem;
}
.sub-heading {
  font-family: Roboto;
  font-weight: 800;
  color: #f9f9f9;
  margin-bottom: 2rem;
  border: 2px solid #f9f9f9;
  padding: 0.3rem 0.7rem;
}
.logos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
  align-items: center;
  justify-items: center;
  margin-bottom: 4rem;
}
.expertise-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
  align-items: center;
  justify-items: center;
}
.logo-grid-logo {
  width: 12rem;
}
.coloured {
  -webkit-filter: none;
          filter: none;
}
.expertise {
  color: #f2d57e;
  font-family: Roboto, Sans-Serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 2419px) {
  .intro-p {
    margin: 2rem 32rem;
  }
}

@media only screen and (max-width: 2059px) {
  .intro-p {
    margin: 2rem 16rem;
  }
}

@media only screen and (max-width: 1920px) {
  .intro-p {
    margin: 2rem 23rem;
  }
}

@media only screen and (max-width: 1763px) {
  .intro-p {
    margin: 2rem 6rem;
  }
}

@media only screen and (max-width: 1508px) {
  .work-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1344px) {
  .page-item {
    grid-template-columns: 1fr;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .page-item-img {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 988px) {
  .work-container {
    grid-template-columns: 1fr;
  }
  .video-container {
    text-align: center;
  }
}

@media only screen and (max-width: 980px) {
  .page-title-container {
    margin-top: 1.2rem;
    margin-bottom: 0.3rem;
  }
  .text-diamond {
    font-size: 0.8rem;
  }
  .intro-headings {
    grid-template-columns: 1fr;
    font-size: 2rem;
  }
  .intro-heading-item {
    margin-bottom: 0.5rem;
  }
  .text-diamond {
    margin-bottom: 0.5rem;
  }
  .desktop-only {
    display: none;
  }
  .main-logo-container {
    width: 80vw;
  }
  .mobile-only {
    display: flex;
  }
  .intro-p {
    margin: 2rem 1rem;
  }
  .logo-small-container {
    width: 0;
  }
  .p1 {
    font-size: 2rem;
  }
  .buttonName {
    font-size: 3.5rem;
  }
  .button-text {
    font-size: 2.1rem;
    padding: 0 0.5rem;
  }
  .button-direction {
    width: 1.4rem;
  }
  .page-button.bottom {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
  .logo-small-home-invisible {
    display: none;
  }
  .text-bg-container.contact {
    width: 30rem;
  }
  .heading.contact {
    font-size: 4.3rem;
  }
  .contact-details {
    margin-top: 5rem;
  }
  .logos-grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .expertise-grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
}

@media only screen and (max-width: 610px) {
  .heading {
    font-size: 2.8rem;
  }
  .diamond-period {
    font-size: 1rem;
  }
  .text-bg-container {
    width: 18rem;
  }
  .page-item-img {
    width: 14rem;
  }
  .p1 {
    font-size: 1.4rem;
  }
  .text-bg-container.contact {
    width: 18rem;
  }
  .heading.contact {
    font-size: 2.7rem;
  }
  .contact-details {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 554px) {
  .social-icons {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.8rem;
  }
  .copyright {
    padding-left: 1rem;
  }
  .page-item {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .videosmall {
    width: 21rem;
    height: 11rem;
  }
  .logo-grid-logo {
    width: 8rem;
  }
}

@media only screen and (max-width: 420px) {
  .logo-small-mobile {
    width: 46vw;
  }
}

@media only screen and (max-width: 362px) {
  .heading {
    font-size: 1.6rem;
    padding-left: 0.3rem;
    padding-right: 0.2rem;
  }
  .text-bg-container {
    width: 12rem;
  }
  .diamond-period {
    font-size: 0.7rem;
  }
  .videosmall {
    width: 18rem;
    height: 10rem;
  }
  .heading.contact {
    font-size: 2rem;
  }
}

.contactModalOff {
    display: none;
    position: fixed;
    border: 3px solid #f1f1f1;
}

.contactModalON {
    display: contents;
}
